var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: {
              label: "项目名",
              prop: "project",
              "min-width": "200",
              fixed: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "环境", prop: "env", "min-width": "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateAppConfig(scope.row)
                          },
                        },
                      },
                      [_vm._v("设置校验Schema\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "设置校验Schema",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "64", disabled: "" },
                    model: {
                      value: _vm.form.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectName", $$v)
                      },
                      expression: "form.projectName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "环境" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "8", disabled: "" },
                    model: {
                      value: _vm.form.env,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "env", $$v)
                      },
                      expression: "form.env",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "校验Schema" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autosize: "" },
                    model: {
                      value: _vm.form.validationSchema,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "validationSchema", $$v)
                      },
                      expression: "form.validationSchema",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "格式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.format,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "format", $$v)
                        },
                        expression: "form.format",
                      },
                    },
                    _vm._l(_vm.formatOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }